<template>
  <section class="hero is-fullheight">
    <main>
      <section class="section">
        <div class="columns is-multiline is-centered is-vcentered has-text-centered">
          <div class="column is-12">
            <img class="image_not_found" src="@/assets/image/not_found.png" alt="404">
          </div>
          <div class="column is-12">
            <h1 class="title has-text-centered">
              ¡Ooops!<br>¡Página No Encontrada!
            </h1>
            <p>
              Para Ver Más Información Navega<br>En Las Opciones de tu Menú.
            </p>
          </div>
          <div class="column is-6">
            <router-link
              class="button button_ibs is-rounded sm_btn is-fullwidth" to="/"
              type="button"
            >
              Ir al Dashboard
            </router-link>
          </div>
        </div>
      </section>
    </main>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage'
}
</script>

<style scoped>
  .hero.is-fullheight .hero-body {
    padding: 0;
  }

  main {
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
  }

  .section {
    min-height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: center;
    color: #404254;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #5d6990;
  }

  .image_not_found {
    max-width: 100px;
  }

  @media screen and (max-width: 768px) {
    .hero.is-fullheight .hero-body, .columns {
      flex-direction: column;
    }
  }
</style>
